// extracted by mini-css-extract-plugin
export const about_main = "about-module--about_main--199ER";
export const main_text = "about-module--main_text--1xqfV";
export const title = "about-module--title--3WLkD";
export const desc = "about-module--desc--g_Kdg";
export const banner_shadow = "about-module--banner_shadow--xuNIq";
export const about_banner = "about-module--about_banner--3j9mG";
export const about_cover = "about-module--about_cover--3bpEg";
export const cover_text = "about-module--cover_text--uG9OA";
export const cover_title = "about-module--cover_title--2KQIm";
export const cover_desc = "about-module--cover_desc--2g4hP";
export const cover_btn = "about-module--cover_btn--2iunG";
export const about_bg = "about-module--about_bg--G8NYq";
export const about_last_main = "about-module--about_last_main--1sv8C";
export const about_last_text = "about-module--about_last_text--3Ghn9";