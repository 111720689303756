import React from "react";
import * as styles from "./about.module.css";
import bgBanner from "../../images/aboutBanner.png";

//
export default function Aboutfirst() {
  return (
    <div className={`${styles.about_main} d-flex justify-center flex-column`}>
      <div className={` ${styles.main_text} pl-30 pr-30 bs-bb text-center`}>
        <h2 className={`${styles.title}  `} style={{fontFamily: `Montserrat, sans-serif`}}>
          About <span className="text-zinc">Us</span>
        </h2>
        <p className={`${styles.desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
          We are a software development company. We’re here to help startup founders design mobile and web solutions for the cloud to attract investors.
        </p>
      </div>
      <div className={`${styles.banner_shadow} `}>
        <div
          className={`${styles.about_banner} container-el d-flex justify-center flex-column item-center`}
        >
          <img alt="banner" src={bgBanner} />
          <span className=""></span>
        </div>
      </div>
    </div>
  );
}
