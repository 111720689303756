import React from "react";
import * as  styles from "./about.module.css";

export default function AboutLastComp() {
  return (
    <div className={`${styles.about_last_main} `}>
      <div
        className={`${styles.about_last_text} m-auto text-center pl-30 pr-30 bs-bb`}
      >
        <h3 className={`${styles.cover_title} mb-30 `} style={{fontFamily: `Montserrat, sans-serif`}}>
          How <span className="text-zinc">It Started</span>
        </h3>
        <p className={`${styles.cover_desc}  mb-0`} style={{fontFamily: `Montserrat, sans-serif`}}>
          After five years of becoming friends in college, Calaunte and Nathan
          teamed up to form a software development partnership. They
          consistently met startup founders who didn't have a strong technology background.
          <br />
          <br />
          Listening to these pain points, our founders set out to create a five
          step process to help startup founders roadmap their product ideas to be better prepared to raise funding for their product.
        </p>
      </div>
    </div>
  );
}
