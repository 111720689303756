import * as React from "react";
import { Layout } from "../components/Layout";
import HeaderCompo from "../components/header/Header";
import aboutBg from "../images/aboutbg.png";
import Aboutfirst from "../components/aboutUs/aboutfirst";
import AboutCover from "../components/aboutUs/aboutBanner";
import AboutBg from "../components/aboutUs/aboutbg";
import AboutLastComp from "../components/aboutUs/aboutLast";
import SEO from "../components/seo";

export default function about() {
  return (
    <>
      <Layout>
      <SEO title="About Us | Zector" description="Zector is a software development company that helps startup founders design web and mobile solutions in the cloud to attract investors."/>
        <HeaderCompo
          headerTitle="About Us"
          headerBtn="Tell us about your project"
          headerbg={aboutBg}
        />
        <Aboutfirst />
        <AboutCover />
        <AboutBg />
        <AboutLastComp />
      </Layout>
    </>
  );
}
