import React from "react";
import * as styles from "./about.module.css";
import { Link } from "gatsby";


export default function AboutCover() {
  return (
    <div className={`${styles.about_cover} d-flex item-center`}>
      <div className="container-el container-lg  ">
        <div
          className={`${styles.cover_text}  pl-30 pr-30 bs-bb d-flex flex-column justify-center item-center text-center m-auto`}
        >
          <h3 className={`${styles.cover_title} mb-30 `} style={{fontFamily: `Montserrat, sans-serif`}}>
            What <span className="text-zinc">We Do</span>
          </h3>
          <p className={`${styles.cover_desc} `} style={{fontFamily: `Montserrat, sans-serif`}}>
            We create a roadmap for your startup so that you have something tangible to pitch to investors in order to raise funding to build your product.
          </p>
          <Link
            to="/contact"
            className={`${styles.cover_btn} d-flex justify-center item-center no-underline text-white`}
            style={{fontFamily: `Montserrat, sans-serif`}}
          >
            Contact Us
          </Link>
        </div>
      </div>
    </div>
  );
}
